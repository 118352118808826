import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FEATURE_MANAGEMENT_API_CONST } from '@app/core/constants/api.constant';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import { FeatureModel } from '@app/shared/models/feature-management/feature.model';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  tap,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private http: HttpClient) {}

  private beforeLoginFeatureList$ = new BehaviorSubject<FeatureModel[] | null>(
    null,
  );
  getAllFeatures(): Observable<FeatureModel[]> {
    const cachedFeatures = this.beforeLoginFeatureList$.getValue();

    if (!cachedFeatures) {
      // Make HTTP call only if features are not cached
      const api$ = this.http
        .get<CustomHttpResponse<FeatureModel[]>>(
          FEATURE_MANAGEMENT_API_CONST.GET_ALL_FEATURES,
        )
        .pipe(
          tap((res) => this.beforeLoginFeatureList$.next(res.data)), // Cache the fetched features
          map((res) => res.data),
          // shareReplay(1), // Share and replay the response to multiple subscribers
          catchError((error) => {
            console.error('Error while fetching features:', error);
            return throwError(() => error); // Rethrow for the test to handle
          }),
        );

      return api$;
    }
    // Return cached features as an observable
    return of(cachedFeatures as FeatureModel[]);
  }

  emitbeforeLoginFeatureList(data: FeatureModel[]): void {
    this.beforeLoginFeatureList$.next(data); // Emit features to subscribers
  }

  beforeLoginFeatureList(): Observable<FeatureModel[] | null> {
    return this.beforeLoginFeatureList$.asObservable();
  }
}
