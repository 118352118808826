import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { UserDetailService } from '@app/core/services/user-detail/user-detail.service';
import { BusinessAreaModel } from '@app/shared/models/manage-business-area/business-area.model';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-business-organisation-dropdown',
  standalone: true,
  imports: [CommonModule, BsDropdownModule],
  templateUrl: './business-organisation-dropdown.component.html',
  styleUrls: ['./business-organisation-dropdown.component.scss'],
})
export class BusinessOrganisationDropdownComponent implements OnDestroy {
  @Input() businessAreas: BusinessAreaModel[] = [];
  selectedBusinessArea: BusinessAreaModel | null = null;
  updateBusinessArea: BusinessAreaModel[] = [];
  private subscription: Subscription;

  constructor(private userDetailService: UserDetailService) {
    this.subscription = this.userDetailService.selectedBusinessArea$.subscribe(
      (value) => {
        this.selectedBusinessArea = value;
      },
    );
  }

  onChangeBusinessArea(businessArea: BusinessAreaModel) {
    this.userDetailService.setBusinessArea(businessArea);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
