import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MANAGE_BUSINESS_AREA_API_CONST } from '@app/core/constants/api.constant';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import {
  BusinessAreaModel,
  BusinessAreaRequestModel,
} from '@app/shared/models/manage-business-area/business-area.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessAreaService {
  constructor(private http: HttpClient) {}

  getAllBusinessAreas(): Observable<CustomHttpResponse<BusinessAreaModel[]>> {
    const url = MANAGE_BUSINESS_AREA_API_CONST.GET_ALL_BUSINESS_AREA;
    return this.http.get<CustomHttpResponse<BusinessAreaModel[]>>(url);
  }

  addBusinessArea(
    payload: BusinessAreaRequestModel,
    id: string | null,
  ): Observable<CustomHttpResponse<BusinessAreaModel>> {
    const url = MANAGE_BUSINESS_AREA_API_CONST.ADD_UPDATE_BUSINESS_AREA;
    return this.http.post<CustomHttpResponse<BusinessAreaModel>>(url, payload);
  }

  deleteBusinessArea(
    id: string,
  ): Observable<CustomHttpResponse<BusinessAreaModel>> {
    const url = MANAGE_BUSINESS_AREA_API_CONST.DELETE_BUSINESS_AREA + '/' + id;
    return this.http.delete<CustomHttpResponse<BusinessAreaModel>>(url);
  }

  getBusinessAreaDetail(
    businessAreaId: string,
  ): Observable<CustomHttpResponse<BusinessAreaModel>> {
    const url =
      MANAGE_BUSINESS_AREA_API_CONST.GET_ALL_BUSINESS_AREA +
      '/' +
      businessAreaId;
    return this.http.get<CustomHttpResponse<BusinessAreaModel>>(url);
  }
}
