export const SSR_ENV_VARIABLES_CONST = 'ssr/environment-variables';

export const API_LANDSCAPE_API_CONST = {
  DIGITAL_CONTENT: 'digital-content',
  APIS: 'digital-content/apis',
  CONTRACT: 'digital-content/apis/contracts',
  API_VERSIONED_DELETE: 'digital-content/versioned/delete',
  DIGITAL_CONTENT_DOCUMENTATION: 'digital-content/documentation',
  DIGITAL_CONTENT_DOCUMENTATION_DELETE: 'digital-content/documentation/delete',
  DIGITAL_CONTENT_DOCUMENTATION_VALIDATE:
    'digital-content/documentation/validate',
  DIGITAL_CONTENT_GENERATE_CREDENTIALS:
    'digital-content/apis/generate-credentials',
  DIGITAL_CONTENT_FETCH_CREDENTIALS: 'digital-content/apis/fetch-credentials',
  DIGITAL_CONTENT_REVOKE_CREDENTIALS: 'digital-content/apis/revoke-credentials',
  DIGITAL_PRODUCT_GENERATE_CREDENTIALS:
    'digital-content/digital-products/generate-credentials',
  DIGITAL_PRODUCT_FETCH_CREDENTIALS:
    'digital-content/digital-products/fetch-credentials',
  DIGITAL_PRODUCT_REVOKE_CREDENTIALS:
    'digital-content/digital-products/revoke-credentials',
  DIGITAL_CONTENT_DOWNLOAD_SDK_FILES: 'ai/sdk/retrieve',
  RETRIEVE_LANGUAGES_LIST: 'code-generation/supported-programming-languages',
  ANALYTICS_SUBSCRIPTION: 'analytics/subscription',
  DEPLOY_API_TO_OTHER_GATEWAY: 'digital-content/apis/deploy',
};

export const MANAGE_DIGITAL_PRODUCT_API_CONST = {
  DELETE_PRODUCT: 'digital-products/delete',
  CREATE_PRODUCT: 'digital-products',
  GET_ALL_CONTENTS: 'digital-content/page/wrapped-versioned-contents',
  CHECK_DUPLICATE: 'digital-content/check-duplicate',
};
export const MANAGE_IFLOW_CONST = {
  UPDATE_IFLOW: 'digital-content/iflows',
};
export const MANAGE_USER_API_CONST = {
  GET_ALL_REGISTERED_USERS: 'registered-users/page',
  DELETE_USERS: 'registered-users/delete',
  UPDATE_USER: 'users/compact',
  SEARCH_USER: 'users/greedySearch',
  ASSIGN_USER: 'rbac/bulk/assign-role/',
  ASSIGN_USER_TASK_RUNING: 'rbac/bulk/assign-role/task/',
  GET_ASSIGN_USER: 'rbac/page/',
  UN_ASSIGN_ROLE: 'rbac/unassign-role/',
};

export const INVITE_USER_API_CONST = {
  INVITATION: 'onboarding/invitations',
  GET_ALL: 'onboarding/users/pending-invitations',
  SEND_BULK_LOGIN_INVITATION: 'onboarding/tokens/users/first-login/bulk',
};

export const VISIBILITIES_API_CONST = {
  GET_VISIBILITY: 'org/visibilities',
};

export const ROLES_API_CONST = {
  GET_ALL_ROLES: 'users/roles/retrieve/all',
};

export const PRIVACY_POLICY_CONST = {
  REVOKE_POLICY: 'onboarding/users/consent/revoke',
};

export const EXTERNAL_REGISTER_CONST = {
  VALIDATE_INVITATION_TOKEN: 'onboarding/tokens/users/external/signup/validate',
};

export const UI_CUSTOMISATION_API_CONST = {
  GET_UI_CUSTOMISATION: 'org/settings/ui-customisation',
  UPDATE_UI_CUSTOMISATION: 'org/settings/ui-customisation',
  UPDATE_ACTIVE_UI_THEME: 'org/settings/ui-customisation/active',
};

export const FEATURE_MANAGEMENT_API_CONST = {
  UPDATE_FEATURES: 'org/features/update',
  GET_ALL_FEATURES: 'org/features/plan',
};

export const SSO_CONFIG = {
  SSO_SAML_VALIDATE: 'sso/saml/validate',
  GET_UPDATE_SAML_PROFILE: 'sso/profiles',
};

export const SSO_SIGN_IN = {
  SSO_TOKEN_VALIDATE: 'sso/saml-token/validate',
};

export const OPENID_SIGN_IN = {
  SSO_TOKEN_VALIDATE: 'sso/openid-token/validate',
};

export const PUBLIC = {
  GET_CUSTOM_TOKEN: 'sso/custom-token',
};

export const GENERATE_DOCUMETATION = {
  GENERATE_DOCUMETATION_AI: 'ai/documentation',
  SAVE_DOCUMETATION_AI: 'digital-content/documentation',
};

export const CONTACT_US_CONST = {
  BASIC_SUPPORT_EMAIL: 'operations/support/basic/support/email',
};

export const MANAGE_ANALYTICS_API_CONST = {
  GET_ALL_ANAlYTICS_REGISTERED_USERS: 'users/analytics/page',
  ANAlYTICS_SEARCH_USERS: 'users/greedySearch',
  ANAlYTICS_ACCESS_ACTION: 'users/analytics/access/action',
  ANAlYTICS_ACCESS_ACTION_SUPERADMIN: 'users/analytics/access/action/update',
};

export const MANAGE_CUSTOM_ROLES = {
  GET_ALL_PERMISSIONS: 'rbac/permissions',
  ALL_ROLES: 'rbac/roles',
};

export const MANAGE_TAXONOMY = {
  MODIFY_TEXONOMY_DEFINATIONS: 'taxonomies/definitions',
  MODIFY_TAXONS: '/taxa/',
  DELETE_TAXONOMY_DEFINITIONS: 'taxonomies/definitions/delete',
  DELETE_TAXONS: 'taxa/delete',
};
export const API_SECURITY_CONST = {
  VULNERABILITY_CHECK_API: 'ai/security/vulnerability',
  LINTING_CHECK_API: 'ai/security/linting',
  ALL_VULNERABILITY_AND_LINTING_CHECK_API: 'ai/security/all',
};

export const MANAGE_SUBSCRIPTIONS_API_CONST = {
  GET_SUBSCRIPTION_REQUESTS: 'approval/subscription',
  GET_SUBSCRIPTION_REQUESTS_WITH_PAGE: 'approval/subscription/page',
};

export const WORKFLOWS_SETTINGS_API_CONST = {
  WORKFLOWS_SETTINGS_API: 'org/settings/workflow',
};
export const API_DESIGNER_STUDIO_API_CONST = {
  CONVERT_FILE_FORMAT: 'ai/api-studio/convert-file-format',
  PUBLISH_NEW_API: 'digital-content/virtual-gateway/publish-api',
};

export const MANAGE_GROUPS_API_CONST = {
  GET_ALL_GROUPS: 'groups',
  ASSIGN_USERS_TO_GROUP: 'groups/assign',
  UNASSIGN_USER_TO_GROUP: 'groups/unassign',
  GET_RESOURCES_ALLOCATION_GATEWAY: 'business-areas',
  SSE_TASKS: 'tasks',
};

export const MANAGE_BUSINESS_AREA_API_CONST = {
  GET_ALL_BUSINESS_AREA: 'business-areas',
  DELETE_BUSINESS_AREA: 'business-areas',
  ADD_UPDATE_BUSINESS_AREA: 'business-areas',
};
