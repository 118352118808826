import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRIVACY_POLICY_CONST } from '@app/core/constants/api.constant';
import { WEB_ANALYTICS_CONST } from '@app/core/constants/message.constant';
import { AnalyticsActivityTypeEnum } from '@app/shared/enum/analytics/analytics-activity-type.enum';
import { RolesEnum } from '@app/shared/enum/role-enum';
import {
  ActivityInsightModel,
  CountryResponseModel,
} from '@app/shared/models/analytics/user-activity.model';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import { BusinessAreaModel } from '@app/shared/models/manage-business-area/business-area.model';
import {
  CreditsModel,
  PlansModel,
  RemainingCreditsListModel,
  RemainingCreditsModel,
  UserCompactModel,
} from '@app/shared/models/manage-user/user-compact-detail.model';
import { RevokePolicyRequest } from '@app/shared/models/terms-privacy/terms-privacy.model';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { CONSTANTS } from '../../constants/constant';
import { AnalyticsService } from '../analytics/analytics.service';
import { BusinessAreaService } from '../business-area/business-area.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailService {
  constructor(
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private businessAreaService: BusinessAreaService,
    private toastService: ToastService,
  ) {
    //TODO: Need to remove this logic (old-one)
    const encodedUserRoles = localStorage.getItem('userRoles') as string;
    const currentUserRoles = encodedUserRoles
      ? JSON.parse(atob(encodedUserRoles))
      : ([] as RolesEnum[]);
    if (currentUserRoles)
      this.currentUserHighestRole = currentUserRoles?.includes(
        RolesEnum.SUPERADMIN,
      )
        ? RolesEnum.SUPERADMIN
        : currentUserRoles?.includes(RolesEnum.ADMIN)
        ? RolesEnum.ADMIN
        : null;
    this.currentUserHighestRole = RolesEnum.ADMIN;

    //TODO: Add new logic on permissions from local-storage
  }
  public currentUserRoles: RolesEnum[] = [];
  // FIXME: [FIX-ROLES]: remove roles dependency from here, code refactoring
  public currentUserHighestRole: RolesEnum | null = null;

  userCompactDetail = new BehaviorSubject<UserCompactModel | null>(null);
  userCompactDetail$ = this.userCompactDetail.asObservable();

  userPlanDetail = new BehaviorSubject<PlansModel | null>(null);
  userPlanDetail$ = this.userPlanDetail.asObservable();

  public currentPermissions: string[] = [];

  userCountry = '';

  allBusinessAreasOfUser: BusinessAreaModel[] = [];
  selectedBusinessArea = new BehaviorSubject<BusinessAreaModel | null>(null);
  selectedBusinessArea$ = this.selectedBusinessArea.asObservable();

  getUserDetails(payload: { email: string }) {
    return this.http.post<CustomHttpResponse<UserCompactModel>>(
      CONSTANTS.API.USER_COMPACT_DETAIL,
      payload,
    );
  }

  updateUserDetails(payload: {
    tokenId: string;
    email: string;
    firstName: string;
    lastName: string;
  }) {
    return this.http.put<CustomHttpResponse<UserCompactModel>>(
      CONSTANTS.API.UPDATE_USER_DETAIL,
      payload,
    );
  }

  // FIXME: [FIX-ROLES]: remove roles dependency from here, code refactoring
  get isAdminOrSuperAdmin() {
    if (
      this.currentUserRoles?.includes(RolesEnum.SUPERADMIN) ||
      this.currentUserRoles?.includes(RolesEnum.ADMIN)
    ) {
      return true;
    }
    return false;
  }

  setBusinessArea(businessArea: BusinessAreaModel) {
    this.selectedBusinessArea.next(businessArea);
  }

  get getAllBusinessAreasOfUser() {
    return this.allBusinessAreasOfUser;
  }

  emitCompactDetail(
    data: UserCompactModel,
    webTrafficType = AnalyticsActivityTypeEnum.WEB_TRAFFIC,
  ) {
    this.getBusinessArea(data);
    const uniquePermissionRoles = this.getAllUniqueRolesPermissions(
      data?.userBusinessAreaGroups ?? [],
    );
    this.currentPermissions = uniquePermissionRoles.permissions;
    this.currentUserRoles = uniquePermissionRoles.roles;

    if (this.currentUserRoles && this.currentUserRoles.length > 0) {
      localStorage.setItem(
        'userRoles',
        btoa(JSON.stringify(this.currentUserRoles)),
      );

      const rolesPriority: RolesEnum[] = [
        RolesEnum.SUPERADMIN,
        RolesEnum.ADMIN,
      ];

      this.currentUserHighestRole = null;

      for (const role of rolesPriority) {
        if (this.currentUserRoles.includes(role)) {
          this.currentUserHighestRole = role;
          break;
        }
      }
    }

    this.userCompactDetail.next(data);
    this.mapRemainingCreditsData(
      data?.userOrganisation?.purchasedProduct?.plan,
      data?.userOrganisation?.purchasedProduct?.remainingCredits,
    );

    if (data) {
      this.getUserCountry().subscribe({
        next: (country) => {
          this.setUserInfoInLocalStorage(
            data,
            this.currentUserRoles,
            country,
            webTrafficType,
          );
        },
        error: () => {
          this.setUserInfoInLocalStorage(
            data,
            this.currentUserRoles,
            WEB_ANALYTICS_CONST.DEFAULT_COUNTRY,
            webTrafficType,
          );
        },
      });
    }
  }

  getBusinessArea(data: UserCompactModel) {
    // FIXME: Need refactoring required clarify on this.
    if (data.userBusinessAreaGroups && data.userBusinessAreaGroups.length > 0) {
      this.allBusinessAreasOfUser = data.userBusinessAreaGroups.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      this.selectedBusinessArea.next(data.userBusinessAreaGroups[0]);
    } else {
      this.businessAreaService.getAllBusinessAreas().subscribe({
        next: (res) => {
          if (res.status_code === 200) {
            if (res.data.length > 0) {
              // const defaultBa = res.data.filter((x) => x.default);

              const defaultBa = res.data;
              data['userBusinessAreaGroups'] = defaultBa;
              this.allBusinessAreasOfUser = defaultBa;
              this.selectedBusinessArea.next(defaultBa[0]);
              this.userCompactDetail.next(data);
            } else {
              this.toastService.error(res.message);
            }
          } else {
            this.toastService.error(res.message);
          }
        },
        error: (err) => {
          this.toastService.error(err.error ? err.error?.error : err.message);
        },
      });
    }
  }

  updateRemainingCredits(remainingCreditsData: RemainingCreditsModel) {
    const planData = this.userPlanDetail.getValue();
    if (remainingCreditsData?.remainingCreditsList) {
      this.mapRemainingCreditsData(
        planData,
        remainingCreditsData?.remainingCreditsList,
      );
    }
  }

  mapRemainingCreditsData(
    planData: PlansModel | null,
    remainingCreditList: RemainingCreditsListModel[],
  ) {
    if (planData && planData?.credits) {
      const formattedPlanData: CreditsModel[] = [];
      planData?.credits.forEach((credit) => {
        if (!credit.disabled) {
          const remainingCreditItem = remainingCreditList.find(
            (t) => credit.id === t.creditTypeId,
          );
          if (remainingCreditItem) {
            credit['remainingCredit'] = remainingCreditItem;
            formattedPlanData.push(credit);
          }
        }
      });
      planData['credits'] = formattedPlanData;
      this.userPlanDetail.next(planData);
    }
  }

  setUserInfoInLocalStorage(
    data: UserCompactModel,
    role: RolesEnum[] | null,
    country: string,
    webTrafficType: AnalyticsActivityTypeEnum,
  ) {
    if (data) {
      const localUserOrgDetail: ActivityInsightModel = {
        user_id: data.userOrganisation.userId || '',
        user_email: data.userModel.email || '',
        user_roles: role,
        user_country: country,
        company_name: data.userOrganisation.organisationName || null,
        company_id: data.userOrganisation.organisationId || null,
        activity_type: AnalyticsActivityTypeEnum.WEB_TRAFFIC,
        activity_date: new Date().toISOString(),
        authentication_mechanism: null,
        referrer_domain: null,
        error: null,
        error_code: null,
      };
      if (
        webTrafficType === AnalyticsActivityTypeEnum.LOGIN ||
        webTrafficType === AnalyticsActivityTypeEnum.REGISTRATION
      ) {
        const loginAnalyticsData: ActivityInsightModel = {
          ...localUserOrgDetail,
          activity_type: webTrafficType,
        };
        this.analyticsService
          .recordUserAnalytics(loginAnalyticsData)
          .subscribe();
      }
    }
  }

  getUserCountry(): Observable<string> {
    if (this.userCountry) return of(this.userCountry);
    const headers = { 'client-side-request': 'true' };
    return this.http
      .get<CountryResponseModel>(environment.countryApiUrl, { headers })
      .pipe(
        map((res: CountryResponseModel) => {
          // TODO: thinking of storing the user-country in browser. So, that it can be reused
          this.userCountry = res.country ?? '';
          return this.userCountry;
        }),
        catchError(() => {
          if (!this.userCountry) this.userCountry = '';
          return this.userCountry;
        }),
      );
  }

  revokeTermsPolicy(payload: RevokePolicyRequest) {
    return this.http.put<CustomHttpResponse<null>>(
      PRIVACY_POLICY_CONST.REVOKE_POLICY,
      payload,
    );
  }

  getCurrentUserRoles(): Observable<RolesEnum[]> {
    const encodedUserRoles = localStorage.getItem('userRoles') as string;
    const roles = encodedUserRoles
      ? JSON.parse(atob(encodedUserRoles))
      : ([] as RolesEnum[]);
    return of(roles);
  }

  checkEmailExistance(email: string): Observable<CustomHttpResponse<boolean>> {
    return this.http.get<CustomHttpResponse<boolean>>(
      CONSTANTS.API.CHECK_EMAIL_EXISTANCE + email,
    );
  }

  /**
   * Filter out unique permissions
   * @param userBusinessAreaGroups : Array of business-areas including roles, including permissions
   * @returns return array of unique permissions
   */
  getAllUniqueRolesPermissions(userBusinessAreaGroups: BusinessAreaModel[]): {
    roles: RolesEnum[];
    permissions: string[];
  } {
    const rolesArrSet = new Set();
    const permissionSet = new Set();
    userBusinessAreaGroups.forEach((businessArea) => {
      businessArea.groups.forEach((group) => {
        group.roles.forEach((role) => {
          if (role?.name) rolesArrSet.add(role.name);
          role.permissions.forEach((permission) => {
            if (permission?.name) permissionSet.add(permission.name);
          });
        });
      });
    });
    return {
      roles: (Array.from(rolesArrSet) ?? []) as RolesEnum[],
      permissions: (Array.from(permissionSet) ?? []) as string[],
    };
  }

  /**
   * validate that at-least one input permissions should exist or not
   * @param acceptedPermissions : array of accepted permissions
   * @returns : boolean value, at-least one is valid or not
   */
  areValidPermissions(acceptedPermissions: string[]): boolean {
    if (this.currentPermissions && this.currentPermissions.length > 0) {
      for (const permission of this.currentPermissions) {
        if (acceptedPermissions.includes(permission)) return true;
      }
    }
    return false;
  }
}
